import { ROLES } from '@pomp-libs/core';
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useAppActions, useAuthActions } from '../hooks';

// TODO: Move user to be the JS object instead of Immutable. Move immutable to be a new output of _depreceatedImmutableUser
/**
 *
 */
function CreateAuthProvider() {
  const Context = createContext({
    user: null,
    isAdmin: false,
    isEsthetician: false,
    isImpersonating: false,
    isAuthed: false,
    isGuest: false,
    enabledFeatures: {},
    loading: false,
    hasFeaturePermission: () => false,
  });

  const { Provider } = Context;

  const AuthProvider = ({ children }) => {
    const { authUser, logout: logoutAction } = useAuthActions();
    const { start } = useAppActions();
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
      setUser(authUser);
    }, [authUser]);

    const userId = useMemo(() => authUser.get('id'), [authUser]);

    // Whenever the user changes, we need to refresh flags and such.
    useEffect(async () => {
      const cachedEvent = localStorage.getItem(`app-start-${userId}-event`);
      if (cachedEvent) {
        try {
          dispatch(JSON.parse(cachedEvent));
        } catch (e) {
          // Fail Gracefully
        }
      }

      // Save this for Google to use later.
      window?.dataLayer?.push({
        event: 'set_user_id',
        userId,
        userRole: authUser.get('role'),
      });

      // We've already got a version cached, no need to show the loading state
      if (!cachedEvent) setLoading(true);
      const { action } = await start();
      setLoading(false);

      localStorage.setItem(`app-start-${userId}-event`, JSON.stringify(action));
    }, [userId, authUser.get('role')]);

    const isAdmin = useMemo(
      () => authUser.get('role') === ROLES.ADMIN,
      [authUser]
    );

    const isEsthetician = useMemo(
      () => authUser.get('role') === ROLES.ESTHETICIAN,
      [authUser]
    );

    const isCustomer = useMemo(
      () => authUser.get('role') === ROLES.CUSTOMER,
      [authUser]
    );

    const isImpersonating = useMemo(
      () => authUser.get('impersonate') !== null,
      [authUser]
    );

    const logout = useMemo(
      () => async () => {
        await logoutAction();
        start();
      },
      [logoutAction, start]
    );

    // TODO: Probably don't need both of these.
    const isAuthed = useMemo(() => Boolean(authUser.get('id')), [authUser]);
    const enabledFeatures = useMemo(
      () =>
        authUser.toJS().featureFlags.reduce((acc, flag) => {
          // TODO: Handle Experimental Features via location.search and LocalStorage
          if (flag.enabled) {
            acc[flag.flag] = true;
          }
          return acc;
        }, {}),
      [authUser]
    );

    const hasFeaturePermission = useMemo(
      () => permission => enabledFeatures[permission],
      [enabledFeatures]
    );

    const isGuest = useMemo(() => authUser.get('id') === null, [authUser]);

    return (
      <Provider
        value={{
          user,
          isAdmin,
          isEsthetician,
          isCustomer,
          isImpersonating,
          isAuthed,
          isGuest,
          loading,
          enabledFeatures,
          hasFeaturePermission,
          logout,
        }}
      >
        {children}
      </Provider>
    );
  };

  AuthProvider.propTypes = {
    children: PropTypes.element,
  };

  const useAuth = () => useContext(Context);

  return {
    AuthProvider,
    useAuth,
  };
}

const { AuthProvider, useAuth } = CreateAuthProvider();

export { AuthProvider, useAuth };
