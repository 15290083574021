export const PRODUCT__ASK_MY_ESTIE_REQUEST = 'PRODUCT__ASK_MY_ESTIE_REQUEST';

// Product rules
export const LOAD__PRODUCT_RULES = 'LOAD__PRODUCT_RULES';
export const ADD__PRODUCTS_TO_BRAND_RULES = 'ADD__PRODUCTS_TO_BRAND_RULES';
export const DELETE__PRODUCT_RULE = 'DELETE__PRODUCT_RULE';
export const EDIT__PRODUCT_RULE = 'EDIT__PRODUCT_RULE';

//Product Images
export const SAVE__PRODUCT_IMAGES = 'SAVE__PRODUCT_IMAGES';
export const SAVE__DEFAULT_IMAGE = 'SAVE__DEFAULT_IMAGE';
export const DELETE__PRODUCT_IMAGE = 'DELETE__PRODUCT_IMAGE';
